@font-face {
  font-family: 'Canela-Light';
  src: url('./fonts/Canela-Light.otf') format('truetype');
}
@font-face {
  font-family: 'Canela-ThinItalic';
  src: url('./fonts/Canela-ThinItalic.otf') format('truetype');
}
@font-face {
  font-family: 'Canela-Thin';
  src: url('./fonts/Canela-Thin.otf') format('truetype');
}
@font-face {
  font-family: 'TTNormsPro';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/TT\ Norms\ Pro\ Regular.otf') format('truetype');
}

body {
  display: flex;
  justify-content: center;
  background-color: #f5f4e7;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
